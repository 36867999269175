import { ReactElement, useState } from "react";

export const InputField = (
	props: {
		showLabel?: boolean,
		centerLabel?: boolean,
		centerInputText?: boolean,
		disableOnEmpty?: boolean,
		button?: {
			content?: ReactElement,
			href?: (value: string) => string,
			onClick?: (value: string) => void,
		}
		onInputChange?: (value: string) => void,
		id?: string,
		title?: string,
		placeholder?: string
	}) => {

	const [inputValue, setInputValue] = useState("");
	const [focused, setFocused] = useState(false);

	const ButtonType = `${props.button?.href ? "a" : "button"}` as keyof JSX.IntrinsicElements;

	return ( <>
		<div className={`${props.centerLabel ? "flex flex-col items-center" : ""}`}>
			{props.showLabel && <label className={`text-lg ${props.centerLabel ? "" : "ml-2"}`} htmlFor={props.id}>{props.title}</label>}
			<div className="rounded-full border-2 border-stone-300 whitespace-nowrap flex items-stretch h-12">
				<input
					onFocus={e => { setFocused(true); }}
					onBlur={e => { setFocused(false); }}

					onKeyDown={e => {
						if(focused && e.key === "Enter" && props.button?.onClick) {
							props.button?.onClick(inputValue);
						}
					}}

					onChange={e => {
						setInputValue(e.currentTarget.value);
						if(props.onInputChange) props.onInputChange(e.currentTarget.value);
					}}

					type="text"
					id={props.id}
					title={props.title}
					placeholder={props.placeholder}
					className={`
						py-2 px-4
						${props.button?.content ? "rounded-l-full" : "rounded-full"}
						${props.centerInputText ? "text-center" : ""}
				`} />

				{props.button &&
					<ButtonType
						href={props.button?.href && props.button?.href(inputValue)}
						onClick={e => { if(props.button?.onClick) props.button?.onClick(inputValue); }}
						className={`
							transition-all duration-100
							h-full flex items-center
							pl-3 pr-4
							rounded-r-full
							font-semibold
							${(props.disableOnEmpty && inputValue === "" ? "pointer-events-none opacity-30" : "hover:bg-stone-300")}
						`}>
						{props.button?.content}
					</ButtonType>
				}
			</div>
		</div>
	</> );
}