export const Footer = () => {
	return ( <>
		<footer className="
			flex-none w-full h-24
			bg-white
			border-t-4 border-zinc-300
			flex items-center justify-center
		">
		<p className="text-sm">
			&copy;2023 Jack Clark
		</p>
		</footer>
	</> );
}