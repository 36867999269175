import { useNavigate } from "react-router-dom";
import { InputField } from "../components/InputField";
import { PlusButton } from "../components/PlusButton";
import { LineDecoration } from "../components/LineDecoration";

export const Home = () => {
	const navigate = useNavigate();

	return ( <>
		<main className="flex flex-col items-center p-8">
			<InputField
				button={{
					content: <>Go</>,
					href: (value) => `poll?id=${value}`,
					onClick: (value) => { navigate(`poll?id=${value}`); }
				}}
				id="poll-id-input"
				placeholder="Poll code"
				showLabel centerLabel title="Enter a poll code"
				disableOnEmpty
			/>

			<LineDecoration>
				OR
			</LineDecoration>

			<PlusButton href="create" id="create-poll-button" showLabel title="Create a new poll" size="large" />
		</main>
	</> );
}