import { Outlet } from "react-router-dom";
import { Nav } from "./components/Nav";
import { Footer } from "./components/Footer";

export const App = () => {
	return ( <>
		<div className="w-full flex flex-col justify-between h-full items-stretch">
			<Nav />
			<div className="flex-auto bg-stone-200">
				<div className="animate-fadeIn">
					<Outlet />
				</div>
			</div>
			<Footer />
		</div>
	</> );
}