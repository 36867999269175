

export const PlusButton = (props: { showLabel?: boolean, title?: string, id?: string, size?: "small" | "medium" | "large", href?: string, onClick?: () => void }) => {

	const sizeMap = {
		"small": ["w-12 h-12", "w-8"],
		"medium": ["w-16 h-16", "w-10"],
		"large": ["w-24 h-24", "w-12"]
	}

	const ButtonType = `${props.href ? "a" : "button"}` as keyof JSX.IntrinsicElements;

	return ( <>
		<div className="flex flex-col items-center">
			{props.showLabel && <label>{props.title}</label>}
			<ButtonType
				href={props.href}
				id={props.id}
				title={props.title}
				className={`
					m-4
					${props.size && sizeMap[props.size][0]}
					border-2 border-stone-300
					rounded-full
					flex justify-center items-center
					group
					hover:bg-stone-300 transition-colors duration-100
				`}>
				<div className="flex justify-center items-center relative group-hover:scale-110 transition-transform duration-100">
					<span className={`${props.size && sizeMap[props.size][1]} h-1 rounded-full bg-stone-600 absolute`}></span>
					<span className={`${props.size && sizeMap[props.size][1]} h-1 rounded-full bg-stone-600 absolute rotate-90`}></span>
				</div>
			</ButtonType>
		</div>
	</> );
}