export const Nav = () => {
	return ( <>
		<header className="
			w-full h-24
			flex items-center max-sm:justify-center
			p-8
			tic-tac-toe
			border-b-4 border-pink-900
		">
			<a title="Home" href="/" className="text-white text-5xl font-extrabold tracking-tighter">
				Polls
			</a>
		</header>
	</> );
}