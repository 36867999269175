

export const LineDecoration = (props: { children?: React.ReactNode }) => {
	return ( <>
		<div className="w-72 space-x-4 flex justify-center items-center my-12">
			<hr className="border-stone-300 border-[1px] w-full" />
			{props.children && <>
				<span>{props.children}</span>
				<hr className="border-stone-300 border-[1px] w-full" />
			</>}
		</div>
	</> );
}