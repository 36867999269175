import { InputField } from "../components/InputField";
import { LineDecoration } from "../components/LineDecoration";
import { PlusButton } from "../components/PlusButton";


export const Create = () => {
	return ( <>
		<main className="flex flex-col items-center p-8">
			<InputField id="new-poll-name" placeholder="Poll name" showLabel centerLabel centerInputText title="New poll name" />

			<LineDecoration>
				<PlusButton size={"small"} id="new-item-button" title="New Item" showLabel />
			</LineDecoration>
		</main>
	</> );
}